<template>
  <div class="container-fluid content">
    <div class="content-container">
      <!-- Sidebar Section -->
      <div class="content-sidebar">
        <user-sidebar :startTour="gettingStarted"></user-sidebar>
      </div>

      <!-- Content Section -->
      <div class="content-section">
        <user-header></user-header>

        <transition name="component-fade" mode="out-in">
          <router-view />
        </transition>
      </div>
    </div>

    <!-- Modal -->
    <modals-container />

    <!-- Dialog -->
    <v-dialog :clickToClose="false"/>

    <!-- Report Feedback -->
    <div class="feedback-wrapper" v-if="showReportForm">
      <report-feedback @close-report-feedback="showReportFeedback" />
    </div>
    <fab
      :position="'bottom-right'"
      :bg-color="'#eaa627'"
      :main-icon="'feedback'"
      :main-tooltip="'Report Feedback'"
      :enable-rotation="true"
      @click.native="showReportFeedback"
    />
  </div>
</template>

<script>
  const UserSidebar = () => import('@/components/shared/UserSidebar');
  const UserHeader = () => import('@/components/shared/UserHeader');
  const UpdateUserType = () => import('@/components/modals/UpdateUserType.vue');
  const WelcomeVideo = () => import('@/components/modals/WelcomeVideo.vue');
  const ReportFeedback = () => import('@/components/shared/ReportFeedback');
  import LevelUpMixin from '@/mixins/LevelUpMixin';
  import { mapActions } from 'vuex';
  import Fab from 'vue-fab';

  export default {
    name : 'Browse',
    head : {
      title : {
        inner      : 'H3',
        separator  : '|',
        complement : 'Healthy Hiphop',
      },
    },
    props : {
      page : String,
    },
    data() {
      return {
        gettingStarted : false,
        showReportForm : false,
      }
    },
    components : {
      Fab,
      ReportFeedback,
      UserSidebar,
      UserHeader,
      // eslint-disable-next-line vue/no-unused-components
      UpdateUserType,
      // eslint-disable-next-line vue/no-unused-components
      WelcomeVideo,
    },
    methods : {
      ...mapActions({
        'getUpdatedUser' : 'user/getUpdatedUser',
      }),

      /**
       * Update First Login
       */
      async updateFirstLogin() {
        if (this.$store.getters['user/user'].first_login === 0) {
          await this.$http.put('api/user', {
            'updateFields' : {
              // eslint-disable-next-line camelcase
              first_login : 1,
            },
          }).then(() => {
            this.getUpdatedUser();
          });
        }
      },

      /**
       * Open Welcome Video Modal
       */
      showWelcomeVideo() {
        this.$modal.show(WelcomeVideo, {}, {
          height       : 'auto',
          width        : 800,
          adaptive     : true,
          clickToClose : false,
          classes      : ['welcome-video-modal'],
        }, {
          'closed' : (event) => {
            this.updateFirstLogin();
            this.gettingStarted = true;
          },
        });
      },

      /**
       * Open Update User Profile Modal
       */
      showUpdateUserProfile() {
        this.$modal.show(UpdateUserType, {}, {
          height       : 'auto',
          adaptive     : true,
          clickToClose : false,
        }, {
          'closed' : (event) => {
            this.getUpdatedUser();
            this.showWelcomeVideo();
          },
        });
      },

      /**
       * Toggle Report Feedback form
       */
      showReportFeedback() {
        this.showReportForm = !this.showReportForm;
      },
    },
    mounted() {
      /**
       * On First Login
       * 1. Show Update User Profile Modal
       * 2. Show Welcome Video
       * 3. Show Getting Started
       */
      if (this.$store.getters['user/user'].user_type !== 6) {
        if (this.$store.getters['user/user'].user_type === 10)
          this.showUpdateUserProfile();
        else if (this.$store.getters['user/user'].first_login === 0)
          this.showWelcomeVideo();
        else if (this.$store.getters['user/user'].first_photo_update === 0)
          this.gettingStarted = true;
      }
    },
    mixins : [
      LevelUpMixin,
    ],
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/components/user/browse";
</style>
